import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { setUseWhatChange } from '@simbathesailor/use-what-changed';

import config from './src/config';
import BackendConnection from './src/GraphQL/BackendConnection';
import { createRoot } from 'react-dom/client';

let ErrorBoundary;
const bugsnagKey = process.env.GATSBY_BUGSNAG_KEY;

if (bugsnagKey) {
  Bugsnag.start({
    apiKey: bugsnagKey,
    enabledReleaseStages: ['production', 'staging', 'development'],
    logger: null,
    plugins: [new BugsnagPluginReact()],
    releaseStage: process.env.CONTEXT || process.env.GATSBY_APP_ENV,
    appVersion: process.env.DEPLOY_ID,
  });

  ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React);
}

// Import June SDK:
import { AnalyticsBrowser } from '@june-so/analytics-next';

// Near the entrypoint of your app, instantiate AnalyticsBrowser:
AnalyticsBrowser.load({
  writeKey: config.JUNE_KEY,
});

setUseWhatChange(config.isLocal);

export const wrapRootElement = ({ element }) => {
  const RootElement = <BackendConnection>{element}</BackendConnection>;

  if (ErrorBoundary) {
    return <ErrorBoundary>{RootElement}</ErrorBoundary>;
  }

  return RootElement;
};

// https://github.com/gatsbyjs/gatsby/discussions/36232#discussioncomment-6145675
export const replaceHydrateFunction = () => {
  return (element, container) => {
    // @ts-ignore
    const root = createRoot(container);
    root.render(element);
  };
};

const disableScrollPaths = [
  '/home/widget',
  '/home/widget/',
  '/home/create-webinar',
  '/home/create-webinar/',
];

// prevent auto scroll to top after open modal using router path
export const shouldUpdateScroll = ({ routerProps, prevRouterProps }) => {
  // https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#shouldUpdateScroll
  const pathname = routerProps?.location?.pathname ?? '';
  const prevPathname = prevRouterProps?.location?.pathname ?? '';
  const isDisableScroll = disableScrollPaths.some((disableScrollPath) => {
    return [pathname, prevPathname].some((path) => path.startsWith(disableScrollPath));
  });
  if (isDisableScroll) {
    return false;
  }
  return true;
};

export const onClientEntry = (): void => {
  if (typeof window !== 'undefined') {
    const originalScriptErrorHandler = window.onerror;

    window.onerror = function (
      message: string | Event,
      source?: string,
      lineno?: number,
      colno?: number,
      error?: Error
    ): boolean | void {
      if (typeof message === 'string' && message.includes('Loading chunk')) {
        console.error('Chunk loading failed. Refreshing the page...');

        // Optionally ask for confirmation
        // if (window.confirm("An update is available. Refresh now?")) {
        window.location.reload();
        // }
      }

      if (originalScriptErrorHandler) {
        // Call the original error handler if it exists
        return originalScriptErrorHandler(message, source, lineno, colno, error);
      }

      return false;
    };
  }
};
