import fetch from 'cross-fetch';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';

import config from '../config';
import typeDefs from './LocalResolver/typeDefs';
import resolvers from './LocalResolver/resolvers';
import fragmentTypes from './schema/fragmentTypes.json';

const removeTypenameLink = removeTypenameFromVariables();

const httpLink = createHttpLink({
  uri: config.GRAPHQL_SERVER_URL,
  fetch,
  fetchOptions: {
    credentials: 'include',
  },
});

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([removeTypenameLink, httpLink]),
  cache: new InMemoryCache({
    possibleTypes: fragmentTypes.possibleTypes,
    typePolicies: {
      InteractionDetails: {
        merge: true,
      },
      Statistic: {
        fields: {
          value: {
            merge: (existing, incoming) => {
              if (existing && incoming === 0) {
                // If the incoming value is 0, but the existing value is not 0, the incoming value is from dummy statistic, let ignore it
                return existing;
              }
              return incoming;
            },
          },
        },
      },
    },
  }),
  resolvers,
  typeDefs,
});
