import gql from 'graphql-tag';

import { MEDIAREF } from './AssetLibrary';
import { STATISTIC_FRAGMENT } from './Ops';
import { ATTENDEE_PROPERTIES_FRAGMENT } from './Property';
import { PUBLIC_EWEBINAR_FRAGMENT } from './PublicEWebinarFragment';

export const REACTION_DETAILS_FIELDS = gql`
  fragment ReactionDetails on ReactionDetails {
    requestToContact {
      name
      phone
      email
      message
      requireEmail
    }
    nextWebinar {
      attendeeId
      setId
      sessionType
    }
    inVideoRegistration {
      attendeeId
      setId
      ungatedSessionType
    }
  }
`;

export const INTERACTION_REACTION_RESULTS_FRAGMENT = gql`
  fragment InteractionReactionResults on Interaction {
    id
    type
    results {
      average
      answers {
        answer
        count
        percent
      }
    }
  }
`;

export const REACTION_FRAGMENTS = gql`
  fragment Reaction on Reaction {
    id
    createdAt
    reactedAt
    reactionAppearRoom
    eventName
    stringAnswer
    numberAnswer
    arrayAnswer
    detailsFields {
      ...ReactionDetails
    }
    interaction {
      ...InteractionReactionResults
    }
  }
  ${REACTION_DETAILS_FIELDS}
  ${INTERACTION_REACTION_RESULTS_FRAGMENT}
`;

export const GET_REACTION_LIKES = gql`
  query reactionLikes($ewebinarSetId: String!) {
    reactionLikes(ewebinarSetId: $ewebinarSetId) {
      timeFrame
      likeCount
    }
  }
`;

const INTERACTION_DETAILS_FRAGMENT = gql`
  fragment DisplayRules on DisplayRule {
    property
    operator
    value
    arrayValue
  }

  fragment InteractionDetails on InteractionDetails {
    imageMediaRef {
      ...MediaRef
    }
    imageQuery
    title
    icon
    subtitle
    description
    shortcut
    email
    requireEmail
    buttonText
    resultsAppearAt
    selectionType
    answers {
      text
      value
      isOther
      isAnswer
    }
    link
    endsIn
    endInReplay
    integration
    isSticky
    endsAt
    lastDays
    hideOnMobile
    showTimer
    showCumulativeResults
    skipWhenChatting
    showOverlayButton
    overlayButtonText
    giphyType
    username
    setId
    registerType
    notifyModerator
    overlaySettings {
      width
      height
      transition
      background
      animation
      text
      direction
      imageMediaRef {
        ...MediaRef
      }
      borderRadius
      imageSize
      fontSize
      position
    }
    anchor {
      pause
      pauseSecs
      pauseMessage
      includeInAgenda
    }
    displayRules {
      ...DisplayRules
    }
    testimonial {
      quote
      action
      name
      title
      avatarMediaRef {
        ...MediaRef
      }
      company
      companyLink
    }
    inVideoRegistration {
      displayIn
      isRequired
      showOnlyBuiltInFields
      hideConsentCheckbox
      buttonColor
      buttonText
    }
  }

  ${MEDIAREF}
`;

export const INTERACTION_FRAGMENT = gql`
  fragment Interaction on Interaction {
    id
    interactionShortId
    type
    valueType
    room
    appearAt
    propertyName
    createdAt
    updatedAt
    details {
      ...InteractionDetails
    }

    isFavorite
    templateId
    isNewTemplate
    templateCategories
    templateName
    templateDesc
    templateDetails {
      instructions
      imageMediaRef {
        ...MediaRef
      }
    }
  }

  ${INTERACTION_DETAILS_FRAGMENT}
`;

export const DRAFT_INTERACTION_FRAGMENT = gql`
  fragment DraftInteraction on DraftInteraction {
    type
    room
    appearAt
    propertyName
    details {
      ...InteractionDetails
    }
  }

  ${INTERACTION_DETAILS_FRAGMENT}
`;

export const INTERACTION_WITH_STATS_FRAGMENT = gql`
  fragment InteractionWithStats on Interaction {
    ...Interaction
    stats {
      ...Statistic
    }
  }

  ${INTERACTION_FRAGMENT}
  ${STATISTIC_FRAGMENT}
`;

export const CONVERSION_NOTIFICATION_FRAGMENT = gql`
  fragment ConversionNotification on ConversionNotification {
    title
    description
    fromDate
    imageUrl
  }
`;

export const INTERACTION_WITH_REACTION_FRAGMENT = gql`
  fragment InteractionWithReaction on Interaction {
    ...Interaction
    ...InteractionReactionResults
    reaction(attendeeId: $attendeeId) {
      id
      reactedAt
      numberAnswer
      stringAnswer
      arrayAnswer
      detailsFields {
        ...ReactionDetails
      }
      properties {
        ...AttendeeActionProperties
      }
    }
  }
  ${INTERACTION_FRAGMENT}
  ${REACTION_DETAILS_FIELDS}
  ${ATTENDEE_PROPERTIES_FRAGMENT}
  ${INTERACTION_REACTION_RESULTS_FRAGMENT}
`;

export const INTERACTION_FOR_ATTENDEE_FRAGMENT = gql`
  fragment InteractionForAttendee on Interaction {
    ...Interaction
    reaction(attendeeId: $attendeeId) {
      id
      reactedAt
      numberAnswer
      stringAnswer
      arrayAnswer
      detailsFields {
        ...ReactionDetails
      }
    }
  }
  ${INTERACTION_FRAGMENT}
  ${REACTION_DETAILS_FIELDS}
`;

export const GET_INTERACTIONS_FOR_ATTENDEE = gql`
  query getInteractionsForAttendee($ewebinarId: String!, $attendeeId: String!) {
    interactions(ewebinarId: $ewebinarId) {
      ...InteractionForAttendee
    }
  }
  ${INTERACTION_FOR_ATTENDEE_FRAGMENT}
`;

export const GET_INTERACTIONS = gql`
  query getInteractionsInWebinar($ewebinarId: String!) {
    interactions(ewebinarId: $ewebinarId) {
      ...Interaction
    }
  }
  ${INTERACTION_FRAGMENT}
`;

export const GET_INTERACTION = gql`
  query getInteraction($id: String!) {
    interaction(id: $id) {
      ...Interaction
      ewebinar {
        ...PublicEWebinar
      }
    }
  }
  ${INTERACTION_FRAGMENT}
  ${PUBLIC_EWEBINAR_FRAGMENT}
`;

export const GET_EWEBINAR_INTERACTIONS_IN_SET = gql`
  query getInteractionsInSet(
    $ewebinarSetId: String!
    $includeDraft: Boolean
    $includePublished: Boolean
    $includeLegacyInteractions: Boolean
  ) {
    interactionsInEwebinarSet(
      ewebinarSetId: $ewebinarSetId
      includeDraft: $includeDraft
      includePublished: $includePublished
      includeLegacyInteractions: $includeLegacyInteractions
    ) {
      ...Interaction
    }
  }
  ${INTERACTION_FRAGMENT}
`;

export const CREATE_EWEBINAR_INTERACTION = gql`
  mutation createInteraction($data: InteractionInput!) {
    createInteraction(data: $data) {
      ...Interaction
      ewebinar {
        id
        interactions {
          ...Interaction
        }
      }
      set {
        id
        isPublishable
      }
    }
  }
  ${INTERACTION_FRAGMENT}
`;

export const UPDATE_EWEBINAR_INTERACTION = gql`
  mutation updateInteraction($data: InteractionInput!) {
    updateInteraction(data: $data) {
      ...Interaction
      ewebinar {
        id
        set {
          id
          isPublishable
        }
      }
    }
  }
  ${INTERACTION_FRAGMENT}
`;

export const DELETE_EWEBINAR_INTERACTION = gql`
  mutation deleteInteraction($id: String!) {
    deleteInteraction(id: $id) {
      id
      interactions {
        ...Interaction
      }
      set {
        id
        isPublishable
      }
    }
  }
  ${INTERACTION_FRAGMENT}
`;

export const GET_INTERACTION_ANALYTICS = gql`
  fragment InteractionWithAnalytics on InteractionWithAnalytics {
    totalCount
    totalCorrect
    results {
      average
      answers {
        answer
        count
        percent
      }
    }
    interaction {
      ...InteractionWithStats
    }
  }

  query interactionAnalytics(
    $ewebinarSetId: String!
    $from: String!
    $to: String!
    $includeLegacyInteractions: Boolean!
  ) {
    interactionAnalytics(
      ewebinarSetId: $ewebinarSetId
      from: $from
      to: $to
      includeLegacyInteractions: $includeLegacyInteractions
    ) {
      ...InteractionWithAnalytics
    }
  }

  ${INTERACTION_WITH_STATS_FRAGMENT}
  ${STATISTIC_FRAGMENT}
`;

export const GET_INTERACTION_REACTION_RESULTS = gql`
  query interactionReactionResults($id: String!) {
    interaction(id: $id) {
      ...InteractionReactionResults
    }
  }
  ${INTERACTION_REACTION_RESULTS_FRAGMENT}
`;

export const GET_INTERACTION_TEMPLATE_TYPES = gql`
  query interactionTemplateCategories {
    interactionTemplateCategories
  }
`;

export const GET_INTERACTION_MENUS = gql`
  query interactionsMenu($filter: FilterInteractionMenuInput) {
    interactionsMenu(filter: $filter) {
      title
      items {
        ...Interaction
      }
    }
  }
  ${INTERACTION_FRAGMENT}
`;

export const GET_ALL_INTERACTION_TEMPLATES = gql`
  query interactionTemplates($filter: FilterInteractionTemplateInput) {
    interactionTemplates(filter: $filter) {
      templates {
        ...Interaction
        set {
          id
        }
      }
      nextCursor
    }
  }
  ${INTERACTION_FRAGMENT}
`;

export const CREATE_EWEBINAR_INTERACTION_FROM_TEMPLATE = gql`
  mutation createInteractionFromTemplate($data: CreateInteractionFromTemplateInput!) {
    createInteractionFromTemplate(data: $data) {
      ...Interaction
      ewebinar {
        id
        interactions {
          ...Interaction
        }
      }
      set {
        id
        isPublishable
      }
    }
  }
  ${INTERACTION_FRAGMENT}
`;

export const UPDATE_FAVORITE_INTERACTION = gql`
  mutation updateInteractionFavorite($interactionId: String!, $favorite: Boolean!) {
    updateInteractionFavorite(interactionId: $interactionId, favorite: $favorite) {
      ...Interaction
    }
  }
  ${INTERACTION_FRAGMENT}
`;

//FOR TESTER TOOL
export const CREATE_EWEBINAR_REACTION_FAKER = gql`
  mutation createReactionsTest($data: ReactionTestInput!, $numberAttendee: Float!) {
    createReactionsTest(data: $data, numberAttendee: $numberAttendee) {
      ...Reaction
    }
  }
  ${REACTION_FRAGMENTS}
`;

export const ATTENDEE_REACTION_RESULTS = gql`
  query attendeeReactionResults($ewebinarId: String!, $interactionId: String!) {
    attendeeReactionResults(ewebinarId: $ewebinarId, interactionId: $interactionId) {
      total
    }
  }
`;

export const CREATE_LIKE_REACTION = gql`
  mutation createLikeReaction($data: ReactionInput!, $connectionId: String, $isReplay: Boolean) {
    createLikeReaction(data: $data, connectionId: $connectionId, isReplay: $isReplay) {
      id
    }
  }
`;

export const CREATE_INTERACTION_REACTION = gql`
  mutation createInteractionReaction(
    $attendeeId: String!
    $data: ReactionForInteractionInput!
    $connectionId: String
    $isReplay: Boolean
  ) {
    createInteractionReaction(
      data: $data
      attendeeId: $attendeeId
      connectionId: $connectionId
      isReplay: $isReplay
    ) {
      ...InteractionWithReaction
    }
  }
  ${INTERACTION_WITH_REACTION_FRAGMENT}
`;
